footer {
    text-align: center;
    padding: 5px;
    background-color: #040cf2;
    color: white;
    height: 80px;
    padding-top: 20px;
  }

.under h3 {
  font-size: 25px;
  text-align: center;
  color: #8A2BE2;
}

.under p {
  margin: 20px;
  
}

.under p::first-letter {
  font-size: 150%;
  color: #8A2BE2;
}
  